import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {Button, Checkbox} from '../../../sharedComponents/components'
import {useTranslation} from 'react-i18next'

import PerformTaskForm from './PerformTaskForm'
import {EventStatusCode, PerformableTask, Subtask} from '../../../state/performTask/state'
import PerformTaskTitle from './PerformTaskTitle'
import {FormContainer} from './PerformCoolerCoolingTask'

interface Props {
  task: PerformableTask
  setValue: any
  onAssetSaved: () => void
  payload: any
  handleUpdate: () => void
  handleRemove: () => void
  handleSubmit: () => void
}

interface SubtasksProps {
  tasks?: string[]
  onSelect?: (key: 'doneSubtasks', selected: string[]) => void
  savedSubtasks?: string[]
  savedSubTasksWithActors?: Subtask[]
}

const SubtasksWidget = ({tasks, onSelect, savedSubtasks, savedSubTasksWithActors}: SubtasksProps) => {
  const [doneSubtasks, setDoneSubtasks] = useState<string[]>(savedSubtasks ? savedSubtasks : [])
  const {t} = useTranslation('tasks')

  const unSavedSubtasks = tasks?.filter(t => !savedSubTasksWithActors?.map(t => t.subtaskName).includes(t))

  const handleClick = (i: number) => {
    const clicked = tasks![i]
    if (doneSubtasks.includes(clicked) && unSavedSubtasks?.includes(clicked)) {
      setDoneSubtasks(doneSubtasks.filter(t => t !== clicked))
    } else {
      setDoneSubtasks(doneSubtasks.concat(clicked))
    }
  }

  const handleSelectAll = () => {
    if (tasks) {
      if (doneSubtasks.length === tasks.length) {
        setDoneSubtasks([])
      } else {
        setDoneSubtasks(tasks)
      }
    }
  }

  const checkActor = (task: string) => {
    if (savedSubtasks && savedSubtasks.includes(task)) {
      if (savedSubTasksWithActors) {
        for (let subtask of savedSubTasksWithActors) {
          if (subtask.subtaskName === task && subtask.completedBy) return task + ' - ' + subtask.completedBy
        }
        return task + ' - N/A'
      }
    }
    return task
  }

  useEffect(() => {
    if (onSelect) {
      onSelect('doneSubtasks', doneSubtasks)
    }
  }, [doneSubtasks, onSelect])

  return tasks && tasks.length ? (
    <SubtasksContainer>
      <Checkbox
        data-cy="perform-todo-checkbox-select-all"
        label={t('tasks:todoTask.selectAll', 'Select All')}
        name={t('tasks:todoTask.selectAll', 'Select All')}
        checked={doneSubtasks.length === tasks.length}
        onClick={handleSelectAll}
      />
      <Subtasks>
        {tasks.map((task, index) => (
          <Checkbox
            key={index + task}
            checked={doneSubtasks.includes(task)}
            label={checkActor(task)}
            name={task}
            onClick={() => handleClick(index)}
          />
        ))}
      </Subtasks>
    </SubtasksContainer>
  ) : null
}

const SubtasksContainer = styled.div`
  padding: 1.5rem 0.5rem;
`

const Subtasks = styled.div`
  padding: 1rem 1.6rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
`

const CompleteTaskButton = styled(Button)`
  margin: 1rem;
  width: 90%;
`

const PerformTodoTask = ({task, setValue, onAssetSaved, payload, handleUpdate, handleSubmit, handleRemove}: Props) => {
  const {t} = useTranslation('tasks')

  const handleCompleteTask = () => {
    payload.incompleteToDo = false
    if (task.status === 'in-progress') handleUpdate()
    else handleSubmit()
  }

  return (
    <>
      <FormContainer>
        <PerformTaskTitle task={task} />
        <SubtasksWidget
          tasks={task.subtasks}
          onSelect={setValue}
          savedSubtasks={task.doneSubtasks}
          savedSubTasksWithActors={task.subTasksWithActors}
        />
        <PerformTaskForm
          onInputChange={setValue}
          payload={payload}
          setValue={setValue}
          onAssetSaved={onAssetSaved}
          handleRemove={task.scheduleInstance || task.status === EventStatusCode.IN_PROGRESS ? handleRemove : undefined}
        />
      </FormContainer>
      {task.subtasks && task.subtasks.length > 0 && (
        <CompleteTaskButton
          buttonStyle="primary"
          buttonSize="small"
          data-cy="complete-todo-task"
          data-testid="submit-button"
          onClick={handleCompleteTask}
        >
          {t('tasks:actions.completeTask', 'Complete Task')}
        </CompleteTaskButton>
      )}
    </>
  )
}

export default PerformTodoTask
