import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import AnchorModal, {TinyModalContent} from '../../Molecules/Modals/AnchorModal'
import ConfirmModal from '../../Molecules/Modals/ConfirmModal'
import {Baseline} from '../../Layout/Grid'
import {IconButton} from '../../Atoms/Buttons'

interface UserSelectProps {
  isOpen: boolean
  onClose: () => void
  onDelete?: () => void
  onDuplicate: () => void
}
const TaskMenuModal = ({isOpen, onClose, onDelete, onDuplicate}: UserSelectProps) => {
  const {t} = useTranslation('tasks')

  const [modalOpen, setModalOpen] = useState(false)
  return (
    <>
      {!!onDelete && (
        <ConfirmTaskDeleteModal
          isOpen={modalOpen}
          onClose={() => {
            onClose()
            setModalOpen(false)
          }}
          onDelete={onDelete}
        />
      )}
      <AnchorModal anchorMode={'right'} isOpen={isOpen} onClose={onClose}>
        <TinyModalContent data-cy="task-menu-modal">
          <Baseline>
            <IconButton
              description={t('tasks:actions.duplicateTask', 'Duplicate task')}
              buttonProps={{onClick: () => onDuplicate()}}
            />
            {!!onDelete && (
              <IconButton
                description={t('tasks:actions.deleteTask', 'Delete task')}
                buttonProps={{
                  onClick: () => {
                    onClose()
                    setModalOpen(s => !s)
                  }
                }}
              />
            )}
          </Baseline>
        </TinyModalContent>
      </AnchorModal>
    </>
  )
}

interface ConfirmProps {
  isOpen: boolean
  onClose: () => void
  onDelete: () => void
}
const ConfirmTaskDeleteModal = ({isOpen, onClose, onDelete}: ConfirmProps) => {
  const {t} = useTranslation('tasks')

  return (
    <ConfirmModal
      heading={t('tasks:confirmation.deleteTaskTitle', 'Delete task?')}
      content={t('tasks:confirmation.deleteTaskCheckbox', 'Are you sure you want to delete this task?')}
      actions={[
        {
          label: t('common:actions.cancel', 'Cancel'),
          action: () => onClose()
        },
        {
          label: t('common:actions.delete', 'Delete'),
          action: () => onDelete()
        }
      ]}
      isOpen={isOpen}
    />
  )
}

export default TaskMenuModal
