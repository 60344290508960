import React, {FC, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import {useEquipmentForm} from './EquipmentUtils'
import {useAppState} from '../../../state'
import {MainContent, NarrowRightSidebar, MainLayoutWithoutStretch} from '../../Layout/Layout'
import {Button, ButtonRowWrap, ButtonRowGrid} from '../../Atoms/Buttons'
import {BigLabelInput, LabelInput, Select, Toggler, LabelCheckbox} from '../../Atoms/Forms'
import {Baseline, InvisibleContainer, Grid, Row} from '../../Layout/Grid'
import TypeBackHeaderTitle, {HeaderRow, HeaderActions} from '../../Molecules/ViewComponents/ViewHeader'
import {P, SmallUnit, HR, H5} from '../../Atoms/Typography'
import {Equipment, StorageType, DishwasherType, CoolerType, StorageSensorType, SensorRole} from '../../../state/rest'
import LocationSelect from '../../Organisms/Locations/LocationSelect'
import ConfirmEquipmentDeleteModal from './ConfirmEquipmentDeleteModal'
import {valueExists, localTempValue} from '../../../config/utils'
import {Helmet} from 'react-navi-helmet-async'

const AlarmRangeHeading = styled.div`
  margin-top: 2rem;
  font-size: 0.75rem;
  font-weight: 600;
`

interface FormProps {
  defaultValues?: Equipment
  typeKey?: string
}
const EditEquipment: FC<FormProps> = ({defaultValues, typeKey}: FormProps): any => {
  const {t} = useTranslation(['appliances', 'tasks'])
  const {state} = useAppState()
  // fetch folder data (if editing)
  // TODO! fetch when loading the route?
  const saved = defaultValues ? defaultValues : undefined
  const isTyped = saved?.equipmentType?.id || typeKey
  if (!isTyped) {
    throw new Error('No typekey found')
  }
  const fc = useEquipmentForm(isTyped, saved)

  const isEditing = !!saved
  const storageType = fc.selectedStorageType as StorageType
  const dishwasherType = fc.selectedDishwasherType as DishwasherType
  const coolerType = fc.selectedCoolerType as CoolerType

  const isLorawanSensorWithPrewashExist = () => {
    if (dishwasherType.sequenceType === 'PREWASH-WASH-RINSE') {
      const lorawanSensors = fc.selectedSensors.filter(sensor => sensor.channel === null)
      if (lorawanSensors.length > 0) return true
    }

    return false
  }

  const preWashErrorMessage = isLorawanSensorWithPrewashExist() ? t('appliances:messages.prewashError') : ''

  const isSaveButtonDisabled = () => {
    if (!fc.formState.isValid || fc.loading || !fc.requiredSensorsSelected() || isLorawanSensorWithPrewashExist()) {
      return true
    }

    return false
  }

  useEffect(() => {
    // when selected different cooler/dishwasher type,
    // it might reduce the number of selectable sensors channels,
    // and resetting the channels here will remove the extra one/ones from fc.
    // note that storages and freezers and wastescales have only 1 selectable channel,
    // so preserving the channel is those types is however ok.
    fc.resetSelectedSensors()
  }, [fc.selectedCoolerType, fc.selectedDishwasherType])

  return (
    <MainLayoutWithoutStretch>
      {isEditing ? (
        <Helmet title={t('common:routes.editAppliance', 'Edit appliance')} />
      ) : (
        <Helmet title={t('common:routes.createAppliance', 'Add appliance')} />
      )}
      <ConfirmEquipmentDeleteModal
        isOpen={fc.modalOpen}
        onClose={() => {
          fc.setModalOpen(false)
        }}
        onDelete={fc.remove}
      />
      <MainContent variant="white">
        <HeaderRow>
          <TypeBackHeaderTitle
            label={t(`appliances:equipmentTypes.${fc.selectedEquipmentType.id}`, `${fc.selectedEquipmentType.name}`)}
            path={fc.backPath}
            backLabel={t('appliances:labels.applianceSettings', 'Appliance settings')}
            isEditing={isEditing}
          />
          {isEditing && <HeaderActions onDelete={() => fc.setModalOpen(true)} />}
        </HeaderRow>
        <InvisibleContainer>
          <Grid>
            <Baseline>
              <BigLabelInput
                required
                placeholder={t('appliances:placeholders.newApplianceName', 'New appliance name')}
                labelText={t('appliances:labels.applianceName', 'Appliance name')}
                name="name"
                id="name"
                ref={fc.register({
                  required: `${t('common:validation.nameRequired', 'Name field is required')}`
                })}
                errorMsg={!!fc.errors.name ? fc.errors.name!.message : undefined}
              />

              {fc.showStorageTypeSelect() && (
                <Select
                  required
                  returnId
                  nativeProps={{
                    name: 'storageType',
                    onChange(e) {
                      fc.selectStorageType(e.target.value)
                    },
                    value: storageType ? storageType.id : ''
                  }}
                  id="storageType"
                  label={t(
                    `appliances:storagetypes.${fc.selectedEquipmentType.id!}.select`,
                    `${fc.selectedEquipmentType.name} type`
                  )}
                  options={fc.storageTypeOptions}
                />
              )}

              {fc.showDishwasherTypeSelect() && (
                <Select
                  required
                  returnId
                  nativeProps={{
                    name: 'dishwasherType',
                    onChange(e) {
                      fc.selectDishwasherType(e.target.value)
                    },
                    value: dishwasherType ? dishwasherType.id : ''
                  }}
                  id="dishwasherType"
                  label={t('appliances:dishwashertype.select', 'Dishwasher type')}
                  options={fc.dishwasherTypeOptions}
                />
              )}

              {fc.showCoolerTypeSelect() && (
                <Select
                  required
                  returnId
                  nativeProps={{
                    name: 'coolerType',
                    onChange(e) {
                      fc.selectCoolerType(e.target.value)
                    },
                    value: coolerType ? coolerType.id : ''
                  }}
                  id="coolerType"
                  label={t('appliances:coolertype.select', 'Cooler type')}
                  options={fc.coolerTypeOptions}
                />
              )}

              {valueExists(storageType?.minValue) &&
                valueExists(storageType?.minValue) &&
                valueExists(storageType?.timeLimit) && (
                  <>
                    <H5>{t('appliances:labels.temperatureAlarm', 'Temperature alarm')}</H5>
                    <Row childMargin="1rem">
                      <LabelInput
                        required
                        disabled
                        value={localTempValue(storageType?.minValue, fc.restaurantController.selectedSite!)}
                        labelText={t('common:general.alarmMin', 'Alarm min')}
                      />
                      <LabelInput
                        required
                        disabled
                        value={localTempValue(storageType?.maxValue, fc.restaurantController.selectedSite!)}
                        labelText={t('common:general.alarmMax', 'Alarm max')}
                      />
                      <SmallUnit>°{fc.restaurantController.selectedSite?.temperatureUnit}</SmallUnit>
                      <LabelInput
                        required
                        disabled
                        value={storageType?.timeLimit}
                        labelText={t('appliances:labels.alarmDelay', 'Alarm delay')}
                      />
                      <SmallUnit>min</SmallUnit>
                    </Row>
                  </>
                )}
              {storageType?.sensorType === StorageSensorType.TEMPERATURE_HUMIDITY &&
                valueExists(storageType?.humidityMinValue) &&
                valueExists(storageType?.humidityMaxValue) &&
                valueExists(storageType?.humidityTimeLimit) && (
                  <>
                    <H5>{t('appliances:labels.humidityAlarm', 'Humidity alarm')}</H5>
                    <Row childMargin="1rem">
                      <LabelInput
                        required
                        disabled
                        value={storageType.humidityMinValue}
                        labelText={t('common:general.alarmMin', 'Alarm min')}
                      />
                      <LabelInput
                        required
                        disabled
                        value={storageType?.humidityMaxValue}
                        labelText={t('common:general.alarmMax', 'Alarm max')}
                      />
                      <SmallUnit>%</SmallUnit>
                      <LabelInput
                        required
                        disabled
                        value={storageType?.humidityTimeLimit}
                        labelText={t('appliances:labels.alarmDelay', 'Alarm delay')}
                      />
                      <SmallUnit>min</SmallUnit>
                    </Row>
                    <P variant="small">
                      {t(
                        'appliances:messages.humidityDisclaimer',
                        'Applies only if appliance sensor supports humidity measurement.'
                      )}
                    </P>
                  </>
                )}
              <Row>
                <P variant="small">
                  {t(
                    'appliances:messages.contactAdmin',
                    'To deviate alarm limits or delay locally, please contact your organisation admin.'
                  )}
                </P>
              </Row>
              <HR />
              {fc.showDishwasherTypeSelect() && (
                <>
                  {fc.showPrewash() && (
                    <>
                      <AlarmRangeHeading>
                        {t('appliances:labels.dishwasherSequence.Prewash', 'Prewash')}
                      </AlarmRangeHeading>
                      <Row childMargin="1rem">
                        <LabelInput
                          required
                          disabled
                          value={localTempValue(
                            dishwasherType?.prewashMinValue!,
                            fc.restaurantController.selectedSite!
                          )}
                          labelText={t('common:general.alarmMin', 'Alarm min')}
                        />
                        <LabelInput
                          required
                          disabled
                          value={localTempValue(
                            dishwasherType?.prewashMaxValue!,
                            fc.restaurantController.selectedSite!
                          )}
                          labelText={t('common:general.alarmMax', 'Alarm max')}
                        />
                        <SmallUnit>°{fc.restaurantController.selectedSite?.temperatureUnit}</SmallUnit>
                      </Row>
                    </>
                  )}
                  {fc.showWash() && (
                    <>
                      <AlarmRangeHeading>{t('appliances:labels.dishwasherSequence.Wash', 'Wash')}</AlarmRangeHeading>
                      <Row childMargin="1rem">
                        <LabelInput
                          required
                          disabled
                          value={localTempValue(dishwasherType?.washMinValue!, fc.restaurantController.selectedSite!)}
                          labelText={t('common:general.alarmMin', 'Alarm min')}
                        />
                        <LabelInput
                          required
                          disabled
                          value={localTempValue(dishwasherType?.washMaxValue!, fc.restaurantController.selectedSite!)}
                          labelText={t('common:general.alarmMax', 'Alarm max')}
                        />
                        <SmallUnit>°{fc.restaurantController.selectedSite?.temperatureUnit}</SmallUnit>
                      </Row>
                    </>
                  )}
                  {fc.showRinse() && (
                    <>
                      <AlarmRangeHeading>{t('appliances:labels.dishwasherSequence.Rinse', 'Rinse')}</AlarmRangeHeading>
                      <Row childMargin="1rem">
                        <LabelInput
                          required
                          disabled
                          value={localTempValue(dishwasherType?.rinseMinValue!, fc.restaurantController.selectedSite!)}
                          labelText={t('common:general.alarmMin', 'Alarm min')}
                        />
                        <LabelInput
                          required
                          disabled
                          value={localTempValue(dishwasherType?.rinseMaxValue!, fc.restaurantController.selectedSite!)}
                          labelText={t('common:general.alarmMax', 'Alarm max')}
                        />
                        <SmallUnit>°{fc.restaurantController.selectedSite?.temperatureUnit}</SmallUnit>
                      </Row>
                    </>
                  )}
                  <P variant="small">
                    {t(
                      'appliances:messages.contactAdmin',
                      'To deviate alarm limits or delay locally, please contact your organisation admin.'
                    )}
                  </P>
                  <HR />
                </>
              )}

              {fc.showCoolerTypeSelect() && (
                <>
                  {fc.showCoolerSlow() && (
                    <>
                      {/*<AlarmRangeHeading>{t('appliances:equipmentTypes.cooler', 'Cooler')}</AlarmRangeHeading>*/}
                      <Row childMargin="1rem">
                        <LabelInput
                          required
                          disabled
                          value={localTempValue(
                            coolerType?.slowCycleStartValue!,
                            fc.restaurantController.selectedSite!
                          )}
                          labelText={t('appliances:labels.coolerStartTemperature', 'Start temp.')}
                        />
                        <LabelInput
                          required
                          disabled
                          value={localTempValue(
                            coolerType?.slowCycleTargetValue!,
                            fc.restaurantController.selectedSite!
                          )}
                          labelText={t('appliances:labels.coolerTargetTemperature', 'Target temp.')}
                        />
                        <SmallUnit>°{fc.restaurantController.selectedSite?.temperatureUnit}</SmallUnit>
                        <LabelInput
                          required
                          disabled
                          value={localTempValue(coolerType?.slowCycleTimeLimit!, fc.restaurantController.selectedSite!)}
                          labelText={t('tasks:labels.duration', 'Duration')}
                        />
                        <SmallUnit>min</SmallUnit>
                      </Row>
                    </>
                  )}
                  {fc.showCoolerFast() && (
                    <>
                      {/*<AlarmRangeHeading>{t('appliances:equipmentTypes.cooler', 'Cooler')}</AlarmRangeHeading>*/}
                      <Row childMargin="1rem">
                        <LabelInput
                          required
                          disabled
                          value={localTempValue(
                            coolerType?.fastCycleStartValue!,
                            fc.restaurantController.selectedSite!
                          )}
                          labelText={t('appliances:labels.coolerStartTemperature', 'Start temp.')}
                        />
                        <LabelInput
                          required
                          disabled
                          value={localTempValue(
                            coolerType?.fastCycleTargetValue!,
                            fc.restaurantController.selectedSite!
                          )}
                          labelText={t('appliances:labels.coolerTargetTemperature', 'Target temp.')}
                        />
                        <SmallUnit>°{fc.restaurantController.selectedSite?.temperatureUnit}</SmallUnit>
                        <LabelInput
                          required
                          disabled
                          value={localTempValue(coolerType?.fastCycleTimeLimit!, fc.restaurantController.selectedSite!)}
                          labelText={t('tasks:labels.duration', 'Duration')}
                        />
                        <SmallUnit>min</SmallUnit>
                      </Row>
                    </>
                  )}
                  <P variant="small">
                    {t(
                      'appliances:messages.contactAdmin',
                      'To deviate alarm limits or delay locally, please contact your organisation admin.'
                    )}
                  </P>
                  <HR />
                </>
              )}

              <Toggler
                label={t('appliances:labels.automaticMonitoring', 'Automatic monitoring with sensor')}
                values={[t('common:togglerValues.no', 'No'), t('common:togglerValues.yes', 'Yes')]}
                ref={fc.register()}
                initiallyChecked={fc.sensorEnabled}
                name="sensorEnabled"
                onClick={async () => {
                  fc.setSensorEnabled(!fc.sensorEnabled)
                  fc.triggerValidation()
                }}
              />

              <div
                key={`sensorEnabled_${fc.sensorEnabled}`}
                style={{
                  opacity: fc.sensorEnabled ? 1 : 0,
                  position: fc.sensorEnabled ? 'relative' : 'absolute',
                  zIndex: fc.sensorEnabled ? 0 : -1000
                }}
              >
                <Baseline>
                  {fc.showDishwasherTypeSelect() ? (
                    // dishwashers needs have 1-3 sensors with specific roles (prewash|wash|rinse) set
                    <>
                      {fc.showPrewash() && (
                        <Select
                          required
                          returnId
                          emptyStr={t('appliances:labels.selectSensor', 'Select a sensor')}
                          key={`prewash_sensor_select_${fc.restaurantController.selectedSite}`}
                          nativeProps={{
                            disabled: !fc.sensorEnabled || fc.sensorOptions.length < 1,
                            name: 'prewashSensorChannel',
                            onChange(e) {
                              fc.selectSensor(
                                fc.sensorOptions.find(o => o.id === e.target.value)!.id,
                                SensorRole.prewash
                              )
                            },
                            value: fc.selectedSensor(SensorRole.prewash)?.id
                              ? fc.selectedSensor(SensorRole.prewash)?.id
                              : ''
                          }}
                          id="prewashSensorChannel"
                          label={t('appliances:labels.sensorDeviceId-Prewash', 'Prewash sensor device ID')}
                          options={fc.sensorOptions}
                        />
                      )}
                      {fc.showWash() && (
                        <Select
                          required
                          returnId
                          emptyStr={t('appliances:labels.selectSensor', 'Select a sensor')}
                          key={`wash_sensor_select_${fc.restaurantController.selectedSite}`}
                          nativeProps={{
                            disabled: !fc.sensorEnabled || fc.sensorOptions.length < 1,
                            name: 'washSensorChannel',
                            onChange(e) {
                              fc.selectSensor(fc.sensorOptions.find(o => o.id === e.target.value)!.id, SensorRole.wash)
                            },
                            value: fc.selectedSensor(SensorRole.wash)?.id ? fc.selectedSensor(SensorRole.wash)?.id : ''
                          }}
                          id="washSensorChannel"
                          label={t('appliances:labels.sensorDeviceId-Wash', 'Wash sensor device ID')}
                          options={fc.sensorOptions}
                        />
                      )}
                      {fc.showRinse() && (
                        <Select
                          required
                          returnId
                          emptyStr={t('appliances:labels.selectSensor', 'Select a sensor')}
                          key={`rinse_sensor_select_${fc.restaurantController.selectedSite}`}
                          nativeProps={{
                            disabled: !fc.sensorEnabled || fc.sensorOptions.length < 1,
                            name: 'rinseSensorChannel',
                            onChange(e) {
                              fc.selectSensor(fc.sensorOptions.find(o => o.id === e.target.value)!.id, SensorRole.rinse)
                            },
                            value: fc.selectedSensor(SensorRole.rinse)?.id
                              ? fc.selectedSensor(SensorRole.rinse)?.id
                              : ''
                          }}
                          id="rinseSensorChannel"
                          label={t('appliances:labels.sensorDeviceId-Rinse', 'Rinse sensor device ID')}
                          options={fc.sensorOptions}
                        />
                      )}
                    </>
                  ) : fc.showCoolerTypeSelect() ? (
                    // coolers need 1-2 sensors with specific roles (fast|slow) set
                    <>
                      {fc.showCoolerSlow() && (
                        <Select
                          required
                          returnId
                          emptyStr={t('appliances:labels.selectSensor', 'Select a sensor')}
                          key={`cooler_slow_sensor_select_${fc.restaurantController.selectedSite}`}
                          nativeProps={{
                            disabled: !fc.sensorEnabled || fc.sensorOptions.length < 1,
                            name: 'coolerSlowSensorChannel',
                            onChange(e) {
                              fc.selectSensor(
                                fc.sensorOptions.find(o => o.id === e.target.value)!.id,
                                SensorRole.coolerSlow
                              )
                            },
                            value: fc.selectedSensor(SensorRole.coolerSlow)?.id
                              ? fc.selectedSensor(SensorRole.coolerSlow)?.id
                              : ''
                          }}
                          id="coolerSlowSensorChannel"
                          label={
                            t('appliances:labels.sensorDeviceId-Cooler', 'Cooler sensor device ID') +
                            ` (${coolerType?.slowCycleTimeLimit!} min)`
                          }
                          options={fc.sensorOptions}
                        />
                      )}
                      {fc.showCoolerFast() && (
                        <Select
                          required
                          returnId
                          emptyStr={t('appliances:labels.selectSensor', 'Select a sensor')}
                          key={`cooler_fast_sensor_select_${fc.restaurantController.selectedSite}`}
                          nativeProps={{
                            disabled: !fc.sensorEnabled || fc.sensorOptions.length < 1,
                            name: 'coolerFastSensorChannel',
                            onChange(e) {
                              fc.selectSensor(
                                fc.sensorOptions.find(o => o.id === e.target.value)!.id,
                                SensorRole.coolerFast
                              )
                            },
                            value: fc.selectedSensor(SensorRole.coolerFast)?.id
                              ? fc.selectedSensor(SensorRole.coolerFast)?.id
                              : ''
                          }}
                          id="coolerFastSensorChannel"
                          label={
                            t('appliances:labels.sensorDeviceId-Cooler', 'Cooler sensor device ID') +
                            ` (${coolerType?.fastCycleTimeLimit!} min)`
                          }
                          options={fc.sensorOptions}
                        />
                      )}
                    </>
                  ) : (
                    // just a single sensor for this equipment type
                    <Select
                      required
                      returnId
                      emptyStr={t('appliances:labels.selectSensor', 'Select a sensor')}
                      key={`sensor_select_${fc.restaurantController.selectedSite}`}
                      nativeProps={{
                        disabled: !fc.sensorEnabled || fc.sensorOptions.length < 1,
                        name: 'sensorChannel',
                        onChange(e) {
                          fc.selectSensor(
                            fc.sensorOptions.find(o => o.id === e.target.value)!.id,
                            SensorRole.singleSensor
                          )
                        },
                        value:
                          fc.selectedSensor(SensorRole.default)?.id ||
                          fc.selectedSensor(SensorRole.singleSensor)?.id ||
                          ''
                      }}
                      id="sensorChannel"
                      label={t('appliances:labels.sensorDeviceId', 'Sensor device ID')}
                      options={fc.sensorOptions}
                    />
                  )}

                  {fc.sensorOptions.length <= 0 && (
                    <P variant={'small'}>
                      {t(
                        'appliances:messages.noFreeSensors',
                        'Current site has no free sensors available. You can not add new appliance with sensor enabled, until there are new sensors installed and transmitting data.'
                      )}
                    </P>
                  )}
                </Baseline>
              </div>

              {fc.showWasteCategoriesSelect() && (
                <Select
                  required
                  returnId
                  emptyStr={t('appliances:labels.selectWasteType', 'Select waste type')}
                  nativeProps={{defaultValue: saved?.wasteScaleCategory?.id || ''}}
                  ref={fc.register({required: true})}
                  id="wasteScaleCategory"
                  label={t('appliances:labels.wasteType', 'Waste type')}
                  options={state.wasteScaleCategories.map(w => ({
                    option: w.name,
                    id: w.id
                  }))}
                />
              )}
            </Baseline>

            <Baseline>
              {fc.showDishwasherTypeSelect() && (
                <>
                  <P variant="small">{t('appliances:labels.alarmGeneratingSensor', 'Alarm generating sensor')}</P>
                  <LabelCheckbox
                    name="alarmSensorRoles1"
                    flipped={false}
                    disabled={!fc.showPrewash()}
                    checked={fc.showPrewash() && fc.isAlarmSensorRoleSelected(SensorRole.prewash)}
                    onClick={() => fc.toggleAlarmSensorRole(SensorRole.prewash)}
                    label={t('appliances:labels.dishwasherSequence.Prewash', 'Prewash')}
                  />
                  <LabelCheckbox
                    name="alarmSensorRoles2"
                    flipped={false}
                    disabled={!fc.showWash()}
                    checked={fc.showWash() && fc.isAlarmSensorRoleSelected(SensorRole.wash)}
                    onClick={() => fc.toggleAlarmSensorRole(SensorRole.wash)}
                    label={t('appliances:labels.dishwasherSequence.Wash', 'Wash')}
                  />
                  <LabelCheckbox
                    name="alarmSensorRoles3"
                    flipped={false}
                    disabled={!fc.showRinse()}
                    checked={fc.showRinse() && fc.isAlarmSensorRoleSelected(SensorRole.rinse)}
                    onClick={() => fc.toggleAlarmSensorRole(SensorRole.rinse)}
                    label={t('appliances:labels.dishwasherSequence.Rinse', 'Rinse')}
                  />

                  <LabelInput
                    name="alarmDelayCycles"
                    type="number"
                    required
                    defaultValue={0}
                    ref={fc.register}
                    labelText={t('appliances:labels.alarmDelayWashingCycles', 'Alarm delay (number of washing cycles)')}
                  />
                  <P variant="small">
                    {t(
                      'appliances:labels.alarmDelayWashingCyclesDescription',
                      'Adjust the alarm delay to define after how many faulty wash cycles an alarm is triggered. ' +
                        'With a value 0, an alarm is triggered on the first faulty cycle; ' +
                        'with a value 1 on the second consecutive cycle, and so forth.'
                    )}
                  </P>
                </>
              )}
            </Baseline>
            <Baseline>
              <div style={{color: 'red', marginTop: '5px'}}>{preWashErrorMessage}</div>
            </Baseline>
          </Grid>

          <ButtonRowWrap>
            <div className="empty"></div>
            <ButtonRowGrid>
              <Button name="cancel-button" negative variant="secondary" disabled={fc.loading} onClick={fc.cancel}>
                {t('common:buttons.cancel', 'Cancel')}
              </Button>
              <Button
                variant="secondary"
                disabled={isSaveButtonDisabled()}
                onClick={() => (isEditing ? fc.update() : fc.submit())}
                data-cy="save-button"
              >
                {t('common:buttons.save', 'Save')}
              </Button>
            </ButtonRowGrid>
          </ButtonRowWrap>
        </InvisibleContainer>
      </MainContent>
      <NarrowRightSidebar>
        <LocationSelect controller={fc.restaurantController} />
      </NarrowRightSidebar>
    </MainLayoutWithoutStretch>
  )
}

export default EditEquipment
