import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {VerticalIconButton} from '../../Atoms/Buttons'
import {useAppState} from '../../../state'
import {IconAddItem} from '../../../Assets/Icons/LargeIcons'

const CreateChainButton: FC<{}> = props => {
  const {t} = useTranslation('admin')
  const {actions} = useAppState()

  const createNewChain = async () => {
    await actions.createOrganization()
  }

  return (
    <VerticalIconButton
      icon={<IconAddItem />}
      description={t(`common:buttons.add`, 'Add new')}
      buttonProps={{
        onClick: createNewChain
      }}
    />
  )
}

export default CreateChainButton
