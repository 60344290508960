import React, {ReactNode, useState} from 'react'
import {useNavigation} from 'react-navi'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import {VerticalIconButton, IconButton} from '../../Atoms/Buttons'
import {IconBack, IconDelete} from '../../../Assets/Icons/TinyIcons'
import {Row, TitleRow, IconRow, Baseline} from '../../Layout/Grid'
import {Heading, P} from '../../Atoms/Typography'
import {IconMore} from '../../../Assets/Icons/TinyIcons'
import {IconAddItem, IconSettings} from '../../../Assets/Icons/LargeIcons'
import {colors} from '../../../sharedComponents/colors'

import HeaderMenuModal from './HeaderMenuModal'

interface TitleHeaderProps {
  children: ReactNode | ReactNode[]
}
export const HeaderTitle = ({children}: TitleHeaderProps) => {
  return <Heading level={1}>{children}</Heading>
}

interface HeaderActionProps {
  onAdd?: () => void
  onConfigure?: () => void
  onMenu?: () => void
  onDelete?: () => void
}
export const HeaderActions = ({onAdd, onConfigure, onMenu, onDelete}: HeaderActionProps) => {
  const {t} = useTranslation()
  return (
    <>
      <IconRow>
        {onConfigure ? (
          <span data-cy="configure-icon">
            <VerticalIconButton
              icon={<IconSettings />}
              description={t(`common:buttons.settings`, 'Settings')}
              buttonProps={{
                onClick: () => onConfigure()
              }}
            />
          </span>
        ) : null}
        {onAdd ? (
          <span data-cy="add-icon">
            <VerticalIconButton
              icon={<IconAddItem />}
              description={t(`common:buttons.add`, 'Add new')}
              buttonProps={{
                onClick: () => onAdd()
              }}
            />
          </span>
        ) : null}
        {onDelete ? (
          <span data-cy="delete-icon">
            <VerticalIconButton
              icon={<IconDelete />}
              buttonProps={{
                onClick: () => onDelete()
              }}
            />
          </span>
        ) : null}
        {onMenu ? (
          <span data-cy="action-menu-icon">
            <VerticalIconButton
              icon={<IconMore width="24px" height="24px" color={colors.brand.cobalt} />}
              buttonProps={{
                onClick: () => onMenu()
              }}
            />
          </span>
        ) : null}
      </IconRow>
    </>
  )
}

interface HeaderRowProps {
  children: ReactNode
  description?: string
  modal?: ReactNode
  dataCy?: string
}
export const HeaderRow = ({children, description, modal, dataCy}: HeaderRowProps) => {
  return (
    <TitleRow>
      <div style={{position: 'relative', margin: '2rem 0'}}>
        <div style={{display: 'flex', minHeight: 24, position: 'relative'}}>
          <Row justify="space-between" align="flex-start" data-cy={dataCy}>
            {children}
          </Row>
        </div>
        {modal}
      </div>
      {!!description && <P style={{marginBottom: 0}}>{description}</P>}
    </TitleRow>
  )
}

interface MenuRowProps {
  children: ReactNode
  description?: string
  showMenu?: boolean
  onDelete: () => void
}
export const MenuRow = ({children, description, showMenu = false, onDelete}: MenuRowProps) => {
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <TitleRow>
      <div style={{position: 'relative'}}>
        <div style={{display: 'flex', minHeight: 44, position: 'relative'}}>
          <Row justify="space-between" align="center">
            {children}
            {showMenu && <HeaderActions onMenu={() => setModalOpen(s => !s)} />}
          </Row>
        </div>
        <HeaderMenuModal isOpen={modalOpen} onClose={() => setModalOpen(false)} onDelete={onDelete} />
      </div>
      {!!description && <P style={{marginTop: '2rem'}}>{description}</P>}
    </TitleRow>
  )
}

interface TypeBackHeaderTitleProps {
  label: string
  path?: string
  backLabel?: string
  type?: string
  isEditing: boolean
}
const TypeBackHeaderTitle = ({label, type, isEditing, path, backLabel}: TypeBackHeaderTitleProps) => {
  const nav = useNavigation()
  const {t} = useTranslation()
  return (
    <Baseline margin="0.5rem">
      <Row>
        <BackHeaderTitle>
          {isEditing
            ? t('common:backHeadingText.edit', {defaultValue: 'Edit {{title}}', title: label})
            : t('common:backHeadingText.add', {defaultValue: 'Add {{title}}', title: label})}
          {type && ` ${type}`}
        </BackHeaderTitle>
      </Row>
      <Row>
        <IconButton
          buttonProps={{
            onClick: () => {
              if (path) nav.navigate(path)
              else nav.goBack()
            }
          }}
          icon={<IconBack />}
          description={
            <BackHeaderContainer>
              <Heading level={6}>{backLabel}</Heading>
            </BackHeaderContainer>
          }
        />
      </Row>
    </Baseline>
  )
}

const BackHeaderTitle = styled.h1`
  /*font-family: 'Industry-Bold', 'Industry Bold', sans-serif;*/
  font-family: FavoritStd-Light, sans-serif;
  font-weight: normal;
  font-size: 2rem; /*32px;*/
  line-height: 2rem; /*32px;*/
  letter-spacing: 0;
`

const BackHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
`

interface GBProps {
  label: string
  icon?: ReactNode
  path?: string
  backLabel?: string
}
export const GoBackHeaderTitle = ({label, icon, path, backLabel}: GBProps) => {
  const nav = useNavigation()

  return (
    <Baseline margin="0.5rem">
      <Row>
        <BackHeaderTitle>{label}</BackHeaderTitle>
      </Row>
      <Row>
        <IconButton
          buttonProps={{
            onClick: () => {
              if (path) nav.navigate(path)
              else nav.goBack()
            }
          }}
          icon={<IconBack />}
          description={
            <BackHeaderContainer>
              {icon ? icon : null}
              {backLabel ? <Heading level={6}>{backLabel}</Heading> : null}
            </BackHeaderContainer>
          }
        />
      </Row>
    </Baseline>
  )
}

export default TypeBackHeaderTitle
