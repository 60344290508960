import React, {useState} from 'react'
import {ValueInputWithRange} from '../../../sharedComponents/components'
import {useTranslation} from 'react-i18next'

import InputWithTags from './InputWithTags'
import PerformTaskForm from './PerformTaskForm'
import PerformTaskTitle from './PerformTaskTitle'
import {useAppState} from '../../../state'
import {convertUnit, tempUnitStr, handleFloatInput} from '../../../config/utils'
import {EventStatusCode, PerformableTask} from '../../../state/performTask/state'
import {FormContainer} from './PerformCoolerCoolingTask'

interface PerformCoolingTaskProps {
  task: PerformableTask
  setValue: any
  onAssetSaved: () => void
  payload: any
  handleUpdate: () => void
  handleNumberBlur: (event: any) => void
  handleRemove: () => void
}
const PerformCoolingTask = ({
  task,
  setValue,
  onAssetSaved,
  payload,
  handleNumberBlur,
  handleRemove
}: PerformCoolingTaskProps) => {
  const {state} = useAppState()
  const {t} = useTranslation('tasks')
  const [isRequired, setIsRequired] = useState(true)
  const handleRemoveTask = () => {
    setIsRequired(false)
    if (handleRemove) {
      handleRemove()
    }
  }
  return (
    <FormContainer>
      <PerformTaskTitle task={task} />
      <ValueInputWithRange
        name="value-input"
        value={payload.value}
        label={
          task.status === EventStatusCode.IN_PROGRESS
            ? t('tasks:labels.endTemperature', 'End temperature')
            : t('tasks:labels.startingTemperature', 'Starting temperature')
        }
        onChange={e => setValue('value', handleFloatInput(e.target.value))}
        onBlur={e => handleNumberBlur(e.target.value)}
        unit={tempUnitStr(state.site!.temperatureUnit)}
        minValue={!task.eventId && task.maxValue ? convertUnit(state.site!, task.maxValue) : undefined}
        maxValue={task.eventId && task.minValue ? convertUnit(state.site!, task.minValue) : undefined}
        message={{
          success: t('tasks:inputWithRange.withinTarget', 'The value is within target limits'),
          error: t('tasks:inputWithRange.not.withInTarget', 'The value is not within target')
        }}
        required={isRequired}
      />
      <InputWithTags
        defaultSuggestions={task.defaultSuggestions || []}
        onChange={setValue}
        label={t('tasks:labels.coolingTarget', 'Cooling Target')}
        placeholder={t('tasks:placeholder.coolingTarget', 'Enter the dish that being cooled down or select from below')}
        savedSuggestions={task.suggestions || []}
        required={isRequired}
      />
      <PerformTaskForm
        onAssetSaved={onAssetSaved}
        onInputChange={setValue}
        payload={payload}
        handleRemove={
          task.scheduleInstance || task.status === EventStatusCode.IN_PROGRESS ? handleRemoveTask : undefined
        }
        setValue={setValue}
      />
    </FormContainer>
  )
}

export default PerformCoolingTask
