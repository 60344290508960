import React, {useState, useEffect} from 'react'
import {Progress, ProgressType, Text, TextSize, SharedIcon, IconPalette} from '../../../sharedComponents/components'
import {colors} from '../../../sharedComponents/colors'

import {differenceInSeconds, differenceInMinutes, isPast, addSeconds} from 'date-fns'
import {useTranslation} from 'react-i18next'
import {PerformableTask} from '../../../state/performTask/state'
import {useAppState} from '../../../state'
import {convertUnit, localNumberFormat, tempUnitStr} from '../../../config/utils'
import {DEFAULT_TIME_SECS, SECONDS_IN_MINUTE, UPDATE_INTERVAL_MS} from '../../appliances/ApplianceCoolingProgress'

const AUTOMATIC_COOLING_TEMPERATURE_DISPLAY_INTERVAL = 5000

export interface Alarm {
  id: string
  alarmType: string
  createdAt: string
  lastMeasurement: null | number
  resolvedAt: string
  startedAt: string
}

interface CoolingProgressProps {
  startTime?: string
  targetTime?: string
  automaticCoolingEndTime?: string
  timeLimit?: number
  latestValue?: number

  circleWidth?: string
  iconWidth?: string
  fontSize?: TextSize
  alarms?: Alarm[]
  temperatureValue?: number
}

export const isCoolingInProgress = (taskTypeId: string, status: string) =>
  (taskTypeId === 'cooling' || taskTypeId === 'automaticCooling') && status === 'in-progress'

export const isCoolingTimeExceeded = (targetTime: string | Date) => {
  return isPast(new Date(targetTime))
}

const sToMMSS = (seconds: number) => {
  const m = Math.floor(seconds / 60)
  const s = seconds % 60
  const ss = s < 10 ? '0' + s : s
  const mm = m < 10 ? '0' + m : m
  return `${mm}:${ss}`
}

const sToHMMSS = (seconds: number) => {
  const h = Math.floor(seconds / 3600)
  seconds %= 3600
  const m = Math.floor(seconds / 60)
  const s = seconds % 60
  const ss = s < 10 ? '0' + s : s
  const mm = m < 10 ? '0' + m : m
  return `${h}:${mm}:${ss}`
}

export const getCoolingDurationInMinutes = (task: PerformableTask) => {
  return differenceInMinutes(new Date(task.targetTime!), new Date(task.startTime!))
}

interface CoolingWarningProps {
  taskTypeId: string
  targetTime: Date | string
  alarms: Alarm[]
  hasMeasurementTarget: boolean
}

const WarningMessage = ({color = colors.system.red, message = ''}: {color?: string; message?: string}) => {
  return (
    <div style={{display: 'flex', alignItems: 'center', gap: '0.3rem', marginTop: '0.5rem'}}>
      <div>
        <SharedIcon icon={IconPalette.AlertTriangle} width="1rem" height="1rem" fill={color} />
      </div>
      <Text size="S" color={color}>
        {message}
      </Text>
    </div>
  )
}

export const CoolingWarning = ({taskTypeId, targetTime, alarms, hasMeasurementTarget}: CoolingWarningProps) => {
  const {t} = useTranslation('tasks')

  let errorMessage

  if (alarms.length > 0) {
    if (alarms.find(a => a.alarmType === 'LOW')) {
      errorMessage = t(
        'tasks:warning.cooling.startTemperatureLow',
        'Start temperature is too low. This task has triggered an alarm.'
      )
    }
    // Manual cooling tasks
    if (alarms.find(a => a.alarmType === 'COOLINGTIME') && taskTypeId === 'cooling') {
      errorMessage = t(
        'tasks:warning.cooling.endTemperatureMissing',
        'Cooling time exceed. End temperature is missing. This task has triggered an alarm.'
      )
    }
    // Automatic cooling tasks
    if (alarms.find(a => a.alarmType === 'COOLINGTIME') && taskTypeId === 'automaticCooling') {
      errorMessage = t(
        'tasks:warning.cooling.durationExceeded',
        'Temperature did not reach target within cooling time limit. This has triggered an alarm.'
      )
    }
  }

  if (taskTypeId === 'cooling' && errorMessage) {
    return <WarningMessage message={errorMessage} />
  } else if (taskTypeId === 'automaticCooling') {
    const targetMissing = isCoolingTimeExceeded(targetTime)
      ? t(
          'tasks:warning.automaticCooling.missingTarget.inprogress',
          'Cooling completed. Measurement target is missing.'
        )
      : t('tasks:warning.automaticCooling.missingTarget.completed', 'Measurement target is missing.')
    const missingMessage = !hasMeasurementTarget ? targetMissing : undefined
    return (
      <div>
        {errorMessage && <WarningMessage message={errorMessage} />}
        {!hasMeasurementTarget && <WarningMessage color={colors.system.grey_50} message={missingMessage} />}
      </div>
    )
  }

  return null
}

const CoolingProgress = ({
  startTime,
  automaticCoolingEndTime: automaticCoolingEndedAt,
  timeLimit: maxTime,
  targetTime,
  fontSize,
  alarms,
  temperatureValue: temperature,
  latestValue,
  ...rest
}: CoolingProgressProps) => {
  const {state} = useAppState()
  const site = state.site!

  const [now, setNow] = useState(new Date())
  const [timeRefreshID, setTimeRefreshID] = useState<number | undefined>()
  const [temperatureRefreshID, setTemperatureRefreshID] = useState<number | undefined>()
  const [showTemperature, setShowTemperature] = useState(false)

  const startedTime = new Date(startTime || now)
  const maxTimeSecs = typeof maxTime === 'number' ? maxTime * SECONDS_IN_MINUTE : DEFAULT_TIME_SECS
  const targetEndTime = new Date(typeof maxTime === 'number' ? addSeconds(startedTime, maxTimeSecs) : targetTime || now)
  const coolingTargetDuration = differenceInSeconds(targetEndTime, startedTime)
  const coolingTimeExceeded = !automaticCoolingEndedAt && isCoolingTimeExceeded(targetEndTime)
  const start = coolingTimeExceeded ? targetEndTime : startedTime
  const end = automaticCoolingEndedAt ? new Date(automaticCoolingEndedAt) : now
  const passedTime = differenceInSeconds(end, start)
  const value = coolingTimeExceeded ? coolingTargetDuration : passedTime
  const color = alarms && alarms.length > 0 ? colors.system.red : undefined
  const displayTime = passedTime < 3600 ? sToMMSS(passedTime) : sToHMMSS(passedTime)
  const temperatureValue = temperature ?? latestValue
  const hasTemperatureValue = typeof temperatureValue === 'number'
  const formattedTemperature = localNumberFormat(site.locale, convertUnit(site, temperatureValue))
  const unit = tempUnitStr(site.temperatureUnit)
  const displayLabel =
    hasTemperatureValue && showTemperature
      ? `${formattedTemperature}${unit}`
      : `${coolingTimeExceeded ? '+' : ''}${displayTime}`

  const reset = () => {
    window.clearInterval(timeRefreshID)
    window.clearInterval(temperatureRefreshID)
    setTimeRefreshID(undefined)
    setTemperatureRefreshID(undefined)
    setShowTemperature(false)
  }

  useEffect(() => {
    if (automaticCoolingEndedAt) {
      return
    }
    const timeIntervalId = window.setInterval(() => {
      setNow(new Date())
    }, UPDATE_INTERVAL_MS) // update every 1 seconds
    setTimeRefreshID(timeIntervalId)

    const temperatureIntervalId = window.setInterval(() => {
      setShowTemperature(prev => !prev)
    }, AUTOMATIC_COOLING_TEMPERATURE_DISPLAY_INTERVAL) // update every 10 seconds
    setTemperatureRefreshID(temperatureIntervalId)

    return () => {
      reset()
    }
  }, [])

  useEffect(() => {
    if (automaticCoolingEndedAt) {
      reset()
    }
  }, [automaticCoolingEndedAt])

  return (
    <Progress
      progressType={ProgressType.circular}
      value={value}
      min={0}
      max={coolingTargetDuration}
      strokeWidth="0.125rem"
      label={<Text size={fontSize || 'M'}>{displayLabel}</Text>}
      color={color}
      {...rest}
    />
  )
}

export default CoolingProgress
