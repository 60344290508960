import React from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import {colors} from '../sharedComponents/colors'

import {SuspendedPeriod} from '../state/settings/suspendedPeriods/state'
import {formatClosingPeriod} from '../pages/settings/SiteSuspendedPeriods'
import {Button, Modal, ModalProps, Text} from '../sharedComponents/components'

interface RemoveSuspendedPeriodModalProps extends ModalProps {
  handleRemove: (id: string) => void
  period: SuspendedPeriod
  locale: string
}
const RemoveSuspendedPeriodModal: React.FC<RemoveSuspendedPeriodModalProps> = ({
  handleRemove,
  period,
  locale,
  isOpen,
  onClose,
  ...rest
}) => {
  const {t} = useTranslation()
  const approvePeriodDelete = () => handleRemove(period.id)

  return (
    <StyledModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('settings:title.removePeriodModal', 'Remove closing period')}
      maxWidth="540px"
      minWidth="540px"
      headerBackgroundColor={colors.system.lightGrey_5}
      data-cy={'delete-suspended-period-modal'}
    >
      <InstructionArea>
        <InstructionBlock>
          <Text size="M">
            {t('settings:description.removePeriod', 'You are removing this closing period from the list:')}
          </Text>
          <Text style={{display: 'block'}} size="M">
            {formatClosingPeriod(period.startAt, period.endAt, locale)}
          </Text>
        </InstructionBlock>
        <ButtonRow>
          <Button buttonStyle="secondary" buttonSize="small" data-cy="delete-period-cancel" onClick={onClose}>
            {t('common:buttons.cancel', 'Cancel')}
          </Button>
          <Button
            buttonStyle="primary"
            buttonSize="small"
            data-cy="delete-period-continue"
            onClick={approvePeriodDelete}
          >
            {t('common:buttons.continue', 'Continue')}
          </Button>
        </ButtonRow>
      </InstructionArea>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  h2 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: ${colors.system.darkGrey_85};
  }
`

const InstructionArea = styled.div`
  background-color: ${colors.system.white};
  border-radius: 1rem;
  height: 100%;
  overflow: auto;
  padding: 1.5rem;
`

const ButtonRow = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: center;
  margin-top: 1.5rem;
`

const InstructionBlock = styled.div`
  width: auto;
`

export default RemoveSuspendedPeriodModal
