import React, {useState} from 'react'
import CenteredModal, {LargeModalContent} from './Modals'
import {LabelCheckbox} from '../../Atoms/Forms'
import {Baseline, Row} from '../../Layout/Grid'
import {H1, H2, P} from '../../Atoms/Typography'
import {Button} from '../../Atoms/Buttons'

interface ConfirmModalProps {
  actions: [{action: () => void; label: string}, {action: () => void; label: string}]
  confirm?: string
  heading: string
  subHeading?: string
  content: string
  isOpen: boolean
}
const ConfirmModal = ({actions, confirm, heading, subHeading, content, isOpen}: ConfirmModalProps) => {
  const [valid, setValid] = useState(!!!confirm)
  return (
    <CenteredModal isOpen={isOpen}>
      <LargeModalContent data-cy="confirm-modal">
        <Baseline margin="2rem">
          <H1>{heading}</H1>
          {subHeading && <H2>{subHeading}</H2>}
          <P>{content}</P>
          {confirm && (
            <div>
              <LabelCheckbox
                flipped={false}
                checked={valid}
                onClick={() => setValid(s => !s)}
                label={confirm}
                name="confirmModal"
              />
            </div>
          )}
          <Row justify="flex-end" childMargin="1rem">
            <Button variant="secondary" negative onClick={actions[0].action}>
              {actions[0].label}
            </Button>
            <Button disabled={!valid} variant="secondary" onClick={actions[1].action}>
              {actions[1].label}
            </Button>
          </Row>
        </Baseline>
      </LargeModalContent>
    </CenteredModal>
  )
}

export default ConfirmModal
