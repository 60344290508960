import React from 'react'
import styled from 'styled-components'
import {SingleChoice} from '../../../sharedComponents/components'

import PerformTaskForm from './PerformTaskForm'
import PerformTaskTitle from './PerformTaskTitle'
import {EventStatusCode, PerformableTask} from '../../../state/performTask/state'
import {FormContainer} from './PerformCoolerCoolingTask'

interface ChoicesWidgetProps {
  tasks?: string[]
  onSelect: (key: 'choice', value: string) => void
  selectedChoice: string
}
const ChoicesWidget = ({tasks, onSelect, selectedChoice}: ChoicesWidgetProps) => {
  return tasks && tasks.length > 0 ? (
    <ChoicesWidgetContainer>
      {tasks.map((task, index) => (
        <SingleChoice
          type="radio"
          label={task}
          name="singleChoices"
          value={task}
          id={`${task}_${index}`}
          key={`${task}_${index}`}
          checked={task === selectedChoice}
          onClick={() => onSelect('choice', task)}
          readOnly
        />
      ))}
    </ChoicesWidgetContainer>
  ) : null
}

const ChoicesWidgetContainer = styled.div`
  padding: 2rem 0;
  display: grid;
  grid-auto-flow: row;
  gap: 1rem;
`

interface PerformSingleChoiceTaskProps {
  task: PerformableTask
  setValue: any
  onAssetSaved: () => void
  payload: any
  handleRemove: () => void
}

const PerformSingleChoiceTask = ({
  task,
  setValue,
  onAssetSaved,
  payload,
  handleRemove
}: PerformSingleChoiceTaskProps) => {
  return (
    <FormContainer>
      <PerformTaskTitle task={task} />
      <ChoicesWidget tasks={task.choices} onSelect={setValue} selectedChoice={payload.choice} />
      <PerformTaskForm
        onInputChange={setValue}
        payload={payload}
        onAssetSaved={onAssetSaved}
        handleRemove={!!task.scheduleInstance || task.status === EventStatusCode.IN_PROGRESS ? handleRemove : undefined}
        setValue={setValue}
      />
    </FormContainer>
  )
}

export default PerformSingleChoiceTask
