import React, {useEffect, useState} from 'react'
import styled, {css} from 'styled-components'
import {colors} from '../sharedComponents/colors'

import {useAppState} from '../state'
import {useTranslation} from 'react-i18next'
import {v4 as uuid} from 'uuid'
import {NotificationType} from '../state/state'
import {ERROR_NOTIFICATION_DURATION_MS} from '../pages/appliances/appliancesUtils'
import {Alarm, AlarmSubType, AlarmType} from '../state/alarms/state'
import {TFunction} from 'i18next'
import {
  alarmIconPicker,
  alarmLimitUnit,
  alarmNameFormatter,
  alarmSubtypeToTranslation,
  alarmUnit,
  alarmValueFormatter,
  dateValueFormatter,
  Units
} from '../pages/alarms/formatters'
import {getDatetimeAgo} from '../Components/Atoms/Utils'
import {Link} from 'react-navi'
import LoadingWithPuff from '../Components/Atoms/LoadingWithPuff'
import {getIsBatteryAlarm} from '../state/alarms/utils'
import {TaskTypeEnum, UserLanguage} from '../state/rest'
import {Text as TextInfo} from '../Components/Atoms/Typography'
import {
  ApplianceData,
  ApplianceDataValueAndUnit,
  Button,
  Input,
  Modal,
  ModalProps,
  SharedIcon,
  SmallIconPalette,
  Text,
  Title
} from '../sharedComponents/components'

export type ResolveAlarmModalFormData = {
  resolution: string
  resolvedBy: string
}

interface ResolveAlarmModalProps extends ModalProps {
  alarmId?: string
  title: string
  showApplianceLink?: boolean
  onFormSubmit?: (formData: ResolveAlarmModalFormData) => void
}

export const ResolveAlarmModal: React.FC<ResolveAlarmModalProps> = ({
  isOpen,
  onClose,
  onFormSubmit,
  title,
  alarmId,
  showApplianceLink,
  ...rest
}) => {
  const {state, actions} = useAppState()
  const {t} = useTranslation(['appliances', 'common', 'alarms', 'tasks'])
  const [formData, setFormData] = useState({resolution: '', resolvedBy: ''})
  const [submitting, setSubmitting] = useState(false)

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [loadingTask, setLoadingTask] = useState<boolean>(false)
  const [alarm, setAlarm] = useState<Alarm | null>(null)
  const [followUpTaskCreated, setFollowUpTaskCreated] = useState<boolean | null>(alarm?.followupTaskCreated || false)

  const units: Units = {
    temperature: state.site!.temperatureUnit,
    weight: state.site!.weightUnit
  }
  const site = state.site!
  const locale = state.site!.locale
  const language = state.me?.user.language || UserLanguage.en
  let measuredValue = undefined
  let latestValue = undefined
  let unit = undefined
  let limitUnit = undefined

  if (alarm) {
    measuredValue = alarmValueFormatter(alarm.measuredValue, alarm.type, alarm.subtype, site)
    latestValue = alarm.latestValue
      ? alarmValueFormatter(alarm.latestValue.value, alarm.type, alarm.subtype, site)
      : undefined
    unit = alarmUnit(t, alarm.type, alarm.subtype, alarm.unit, units)
    limitUnit = alarmLimitUnit(t, alarm.type, alarm.subtype, alarm.unit, units)
  }

  useEffect(() => {
    const fn = async () => {
      if (alarmId) {
        const alarm = await actions.v1.alarms.getAlarm({alarmId})
        setAlarm(alarm)
        setIsLoading(false)
      }
    }
    fn()
  }, [alarmId])

  const resetModal = () => {
    setFormData({resolution: '', resolvedBy: ''})
    setSubmitting(false)
    setIsLoading(true)
    setAlarm(null)
    setFollowUpTaskCreated(false)
  }

  const handleButtonClick = async () => {
    setLoadingTask(true)
    const task = await actions.v1.performTask.getTask({taskId: alarm?.taskId!, siteId: state.site!.id!})
    let newEvent = {
      task: task,
      remarks: t('alarms:messages.followupremark', 'Follow-up task'),
      status: '',
      isFollowUp: true,
      ingredients: [''],
      suggestions: ['']
    }
    if (task) {
      if (task.taskType.id === TaskTypeEnum.WASTE) {
        newEvent.ingredients = alarm?.measurementTarget ? [alarm?.measurementTarget] : []
        newEvent.suggestions = []
        console.log('newEvent', newEvent)
      } else {
        newEvent.suggestions = alarm?.measurementTarget ? [alarm?.measurementTarget] : []
        newEvent.ingredients = []
      }
      const result = await actions.v1.performTask.createEvent(newEvent)
      const updatedAlarm = await actions.v1.alarms.updateAlarm({
        alarmId: alarmId,
        siteId: site.id,
        followupTaskCreated: true
      })
      setAlarm({...alarm!, followupTaskCreated: true})
      if (result) {
        setFollowUpTaskCreated(true)
      }
    }
    setLoadingTask(false)
  }

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement> & React.ChangeEvent<HTMLTextAreaElement>) => {
    switch (event.target.id) {
      case 'resolveAlarmModalFormReason':
        setFormData({...formData, resolution: event.target.value})
        break
      case 'resolveAlarmModalFormResolved':
        setFormData({...formData, resolvedBy: event.target.value})
        break
      default:
        break
    }
  }

  const onSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    if (!alarmId || !state.site?.id) {
      return
    }
    setSubmitting(true)
    try {
      await onFormSubmit?.({...formData})
      onClose?.()
      resetModal()
    } catch (err) {
      actions.addNotification({
        id: uuid(),
        type: NotificationType.ERROR,
        title: t('appliances:messages.dataSendErrorTitle', 'Data send error'),
        description: t('appliances:messages.dataSendErrorDescription', 'Sending data failed. Please try again.'),
        visible: true,
        hideAfterDelay: ERROR_NOTIFICATION_DURATION_MS
      })
    }
    setSubmitting(false)
  }

  const onResetAndClose = () => {
    resetModal()
    onClose?.()
  }
  const isBatteryAlarm = getIsBatteryAlarm(alarm)

  const getAlarmInstructions = () => {
    // TODO: show different instructions based in sensor type (different batteries)
    return (
      <>
        <Text data-cy="modal-alarm-instructions-content" size="M" color={colors.system.grey_50}>
          {t(
            `alarms:modal.instruction.description.battery`,
            `The sensor battery level is low. Change the battery to resolve this alarm.`
          )}
        </Text>
        <Title level={6} strong style={{margin: '0'}}>
          {t('alarms:modal.instruction.subTitle.battery', 'How to change the battery?')}
        </Title>
        <Text data-cy="modal-alarm-instructions-self" size="M" color={colors.system.grey_50}>
          {t(
            'alarms:modal.instruction.batteryChange.self',
            `With Laird sensors you can change the batteries yourself. You need two Energizer Ultimate Lithium AA - batteries.`
          )}
        </Text>
        <Text data-cy="modal-alarm-instructions-support" size="M" color={colors.system.grey_50}>
          {t(
            'alarms:modal.instruction.batteryChange.support',
            `If the sensor is not a Laird sensor or you are unsure of the sensor, please contact our customer support.`
          )}
        </Text>
      </>
    )
  }

  return (
    <StyledModal
      id="resolveAlarmModal"
      minHeight="424px"
      maxWidth={isBatteryAlarm ? '1000px' : '578px'}
      isOpen={isOpen}
      onClose={submitting ? undefined : onResetAndClose}
      title={title}
      headerLeftItem={
        <Button buttonStyle="secondary" buttonSize="small" onClick={onResetAndClose} disabled={submitting}>
          {t('common:buttons.cancel', 'Cancel')}
        </Button>
      }
      headerRightItem={
        <Button
          buttonStyle="primary"
          buttonSize="small"
          onClick={onSubmit}
          disabled={!formData.resolution || submitting || !alarm?.isResolvable}
          form="resolveAlarmModalForm"
          data-cy="resolve-alarm-modal-button-solve"
        >
          {t('common:buttons.resolve', 'Resolve')}
        </Button>
      }
      headerBackgroundColor={colors.system.red}
      {...rest}
    >
      {isLoading || alarm === null ? (
        <LoadingWithPuff />
      ) : (
        <ContentContainer showInstructions={isBatteryAlarm}>
          {isBatteryAlarm && (
            <AlarmInstructions
              title={t('alarms:modal.instruction.title', 'Instructions')}
              content={getAlarmInstructions()}
            />
          )}
          <FormArea>
            <AlarmDetails
              alarm={alarm}
              t={t}
              unit={unit}
              minUnit={limitUnit}
              maxUnit={limitUnit}
              formattedValue={measuredValue}
              locale={locale}
            />
            <Form id="resolveAlarmModalForm">
              <Input
                id="resolveAlarmModalFormResolved"
                name="resolveAlarmModalFormResolved"
                label={t('appliances:labels.resolvedBy', 'Resolved By')}
                placeholder={t('appliances:placeholders.resolvedBy', 'Enter name')}
                onChange={onInputChange}
                value={formData.resolvedBy}
                disabled={submitting}
              />
              <Input
                id="resolveAlarmModalFormReason"
                name="resolveAlarmModalFormReason"
                label={t('appliances:labels.description', 'Description')}
                placeholder={t('appliances:placeholders.resolution', 'Enter alarm reason & resolution')}
                multiline
                rows={6}
                onChange={onInputChange}
                value={formData.resolution}
                disabled={submitting}
                required
              />
            </Form>
            {alarm.category === 'task' && alarm.type !== AlarmType.Cooling && (
              <Button
                buttonStyle="primary"
                buttonSize="medium"
                onClick={handleButtonClick}
                disabled={alarm.followupTaskCreated || false}
                data-cy="create-followup-task"
              >
                {loadingTask ? (
                  <LoadingWithPuff />
                ) : (
                  t('alarms:modal.buttons.createFollowupTask', 'Create Follow-Up Task')
                )}
              </Button>
            )}
            {alarm.followupTaskCreated && (
              <TextInfo level={3} color={colors.system.green}>
                {t('alarms:messages.createdFollowupTask', 'Follow-up task has been created.')}
              </TextInfo>
            )}
            {alarm.latestValue && (alarm.subtype === AlarmSubType.High || alarm.subtype === AlarmSubType.Low) && (
              <CurrentMeasurement t={t} language={language} alarm={alarm} unit={unit} formattedValue={latestValue} />
            )}
            {showApplianceLink && alarm.applianceId && (
              <ApplianceLinkContainer>
                <ApplianceLink href={`/appliances/${alarm.applianceId}`}>
                  <Text size="S">{t('alarms:labels.applianceLink', 'View appliance details')}</Text>
                  <SharedIcon icon={SmallIconPalette.ArrowheadRight} width="0.75em" height="0.75em" />
                </ApplianceLink>
              </ApplianceLinkContainer>
            )}
            {alarm.category === 'task' && <TaskDetails alarm={alarm} t={t} />}
          </FormArea>
        </ContentContainer>
      )}
    </StyledModal>
  )
}

const InstructionArea = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  background-color: ${colors.system.lightGrey_5};
  border-radius: 1rem;
  height: 100%;
  overflow: auto;
  padding: 1.5rem;
`

interface AlarmInstructionsProps {
  title: string
  content: JSX.Element
}

const AlarmInstructions = ({title, content}: AlarmInstructionsProps) => {
  return (
    <InstructionArea>
      <Title level={5} strong data-cy="modal-alarm-instructions-title">
        {title}
      </Title>
      {content}
    </InstructionArea>
  )
}

interface AlarmDetailsProps {
  alarm: Alarm
  unit?: string
  maxUnit?: string
  minUnit?: string
  formattedValue?: string | number
  locale: string
  t: TFunction
}

const AlarmDetailsContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 1rem;
  border-bottom: 1px ${colors.system.grey_5} solid;
  padding-bottom: 1rem;
`

const AlarmDetails = ({alarm, t, unit, maxUnit, minUnit, locale, formattedValue}: AlarmDetailsProps) => {
  return (
    <AlarmDetailsContainer>
      <SharedIcon icon={alarmIconPicker(alarm.type)} width="3em" height="3em" fill={colors.brand.cobalt} />
      <div>
        <Text size="XS" color={colors.system.grey_50} textTransform="uppercase">
          {alarmSubtypeToTranslation(t, alarm.type, alarm.subtype)}
        </Text>
        <Title level={5} strong style={{paddingTop: '0.5rem'}}>
          {alarmNameFormatter(t, alarm.name, alarm.sensorRole)}
        </Title>
        <Text size="S">{dateValueFormatter(alarm.triggeredTime, locale)}</Text>
      </div>
      <ApplianceData
        value={formattedValue}
        unit={unit}
        minValue={alarm.limits?.minValue ?? undefined}
        maxValue={alarm.limits?.maxValue ?? undefined}
        minUnit={minUnit}
        maxUnit={maxUnit}
        hasAlarm
      />
    </AlarmDetailsContainer>
  )
}

interface CurrentTemperatureProps {
  t: TFunction
  language: string
  alarm: Alarm
  unit?: string
  formattedValue?: string | number
}

const CurrentMeasurement: React.FC<CurrentTemperatureProps> = ({t, language, alarm, unit, formattedValue}) => {
  const title =
    alarm.type === AlarmType.Humidity
      ? t('common:labels.currentHumidity', 'Current humidity')
      : t('common:labels.currentTemperature', 'Current temperature')

  return (
    <CurrentTemperatureContainer>
      <CurrentTemperatureLabel>
        <Title level={5} strong>
          {title}
        </Title>
        {alarm.latestValue?.receivedAt && (
          <Text size="S">
            {t<string>('appliances:labels.lastUpdate', 'Last update {{ago}}', {
              ago: getDatetimeAgo(new Date(alarm.latestValue.receivedAt), language)
            })}
          </Text>
        )}
      </CurrentTemperatureLabel>
      {typeof alarm.latestValue?.value === 'number' && (
        <ApplianceDataValueAndUnit value={formattedValue} unit={unit} valueTextLevel={2} unitTextSize="S" />
      )}
    </CurrentTemperatureContainer>
  )
}
interface TaskDetailsProps {
  alarm: Alarm
  t: TFunction
}

const TaskDetails: React.FC<TaskDetailsProps> = ({t, alarm}) => {
  return (
    <TaskDetailsContainer>
      <Title level={5} strong>
        {t<string>('tasks:completed.taskDetails', 'Task details')}
      </Title>
      <Column1>
        <Text size="S" color={colors.system.grey_50}>
          {t<string>('tasks:labels.measurementValueTarget', 'Measurement target')}
        </Text>
        <Text size="S">{alarm.measurementTarget}</Text>
      </Column1>
      <Column2>
        <Text size="S" color={colors.system.grey_50}>
          {t<string>('tasks:completed.notes', 'Note')}
        </Text>
        <Text size="S">{alarm.note}</Text>
      </Column2>
      <Column1>
        <Text size="S" color={colors.system.grey_50}>
          {t<string>('tasks:labels.columnNameCompletedBy', 'Completed by')}
        </Text>
        <Text size="S">{alarm.completedBy}</Text>
      </Column1>
    </TaskDetailsContainer>
  )
}

const StyledModal = styled(Modal)`
  h2 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: ${colors.system.white};
  }
`
interface ContentContainerProps {
  showInstructions: boolean
}

const ContentContainer = styled.div<ContentContainerProps>`
  padding: 1rem 1.5rem;
  gap: 1rem;
  display: grid;
  grid-auto-flow: row;
  ${({showInstructions}) =>
    showInstructions &&
    css`
      justify-content: center;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 1.5rem;
      height: 90%;
    `}
`

const FormArea = styled(InstructionArea)`
  background-color: ${colors.system.white};
`

const Form = styled.form`
  display: grid;
  grid-auto-flow: row;
  gap: 1rem;
  width: 100%;
`

const CurrentTemperatureContainer = styled.div`
  padding: 1.5rem;
  background-color: ${colors.system.lightGrey_5};
  border-radius: 0.5rem;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
`

const CurrentTemperatureLabel = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 0.125rem;
`

const ApplianceLinkContainer = styled.div`
  display: grid;
  justify-content: end;

  :hover {
    text-decoration: underline;
  }
`

// TODO: Using the react-navi Link doesn't do page reload but not sure if react-navi should be used in shared-frontend-components to make link component
const ApplianceLink = styled(Link)`
  text-decoration: none;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 0.5rem;

  :visited {
    color: ${colors.brand.cobalt};
  }
`

const TaskDetailsContainer = styled(CurrentTemperatureContainer)`
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto;
  grid-gap: 1rem;
  margin-bottom: 1rem;
  > h5 {
    grid-column: span 2;
  }
`

const Column1 = styled.div`
  grid-row: span 1;
  display: grid;
  grid-template-rows: fit-content;
  align-items: start;
  align-self: start;
  > span:first-of-type {
    padding-bottom: 0.2rem;
  }
`

const Column2 = styled(Column1)`
  grid-row: span 2;
`
